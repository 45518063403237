import { type UrlLink } from '~/composables/widgets';

export const useURL = () => {
    const appStore = useAppStore();
    const localPath = useLocalePathWoom();
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const { website, pageCache, footer } = storeToRefs(appStore);

    const linkStorage = computed(() => [...(pageCache.value?.refs?.links ?? []), ...(website.value?.refs?.links ?? [])]);

    const generateURL = (link: Link, references?: LinkDataUrlReference[] | null): UrlLink => {
        switch (link?.type) {
            case 'sitemap':
            case 'page':
                if (link?.url?.url) {
                    return { url: nuxtApp.$helpers.generateLocalePath(link.url.url) };
                }

                const found = getItemById(link?.data, references) || getItemById<LinkDataUrlReference>(link?.data, linkStorage.value);
                if (!found) return { url: undefined, label: undefined };
                return { url: nuxtApp.$helpers.generateLocalePath(found.url.url) };
            case 'url':
                return {
                    url: link.data.url,
                    label: link.label,
                };
            case 'anchor':
                return { url: `#${link?.data}`, label: link.label };
        }
        return { url: undefined, label: undefined };
    };

    const generateStringOnlyURL = (link: Link, references?: LinkDataUrlReference[] | null) => {
        const result = generateURL(link, references);
        return result?.url;
    };

    const contactUrl = computed(() => {
        if (!footer.value?.contact_link) return;
        return generateStringOnlyURL(footer.value.contact_link);
    });

    const resolveUrlLink = (link: Link | undefined | null): UrlLink => {
        if (link?.type === 'sitemap' || link?.type === 'page') {
            const ref = appStore.getReference<{ id: string; url: UrlResolved }>(link.data, 'links');
            if (ref) {
                return {
                    url: ref.url?.url,
                    label: link.label,
                };
            }
        } else if (link?.type === 'url') {
            return {
                url: link.data.url,
                target: link.data.target,
                label: link.label,
            };
        } else if (link?.type === 'anchor') {
            return {
                url: `#${link.data}`,
                label: link.label,
            };
        }
        return { url: undefined, label: undefined };
    };

    const currentPathWithoutTrailingSlash = computed(() => {
        const currentPath = route.path;
        return decodeURIComponent(currentPath.replace(/\/$/, ''));
    });
    /** compare urls without trailing slash */
    const isUrlCurrentPath = (url?: string) => url !== undefined && currentPathWithoutTrailingSlash.value === localPath(url.replace(/\/$/, ''));

    return {
        contactUrl,
        isUrlCurrentPath,
        generateStringOnlyURL,
        resolveUrlLink,
    };
};
